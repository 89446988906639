<!--
 * @Description: 编辑品牌
 * @Author: 琢磨先生
 * @Date: 2022-06-06 16:55:54
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-15 22:29:36
-->

<template>
  <el-drawer
    v-model="drawer"
    :title="title"
    direction="rtl"
    size="800px"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="LOGO">
        <upload-avatar
          @change="uploadChange"
          :src="form.logo_url"
          :aspectRatio="1"
        ></upload-avatar>
        <div class="help-block">图片格式要求{1:1}，且宽高不能超过800</div>
      </el-form-item>

      <el-form-item label="注册商标号" prop="brand_no">
        <el-radio-group v-model="form.register_type">
          <el-radio label="0">中国大陆</el-radio>
          <el-radio label="1"> 其他国家和港澳台地区</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="品牌名" prop="temp_name">
        <el-row :gutter="10">
          <el-col :span="10">
            <el-input
              v-model="form.name"
              placeholder="中文名"
              @input="form.temp_name = form.name"
            ></el-input>
          </el-col>
          <el-col :span="10">
            <el-input
              v-model="form.en_name"
              placeholder="英文名"
              @input="form.temp_name = form.en_name"
            ></el-input>
          </el-col>
        </el-row>
        <div class="help-block">
          仅有中文品牌只需填中文名称，仅有英文品牌只需填英文名称，中英文品牌请分类填写中文名称和英文名称。
        </div>
      </el-form-item>

      <el-form-item label="所属类目" prop="categoryIds">
        <multiple-category @change="checkedCategory"></multiple-category>
        <el-alert
          type="info"
          v-if="categoryList.length > 0"
          :closable="false"
          style="margin-top: 10px"
        >
          <el-tag
            v-for="(item, i) in categoryList"
            :key="item.value"
            closable
            @close="removeCategory(i)"
            style="margin-right: 20px"
            >{{ item.full_name }}</el-tag
          >
        </el-alert>
      </el-form-item>

      <el-form-item label="注册商标号" prop="brand_no">
        <el-col :span="6">
          <el-input v-model="form.brand_no" placeholder=""></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="品牌所有人" prop="owner">
        <el-col :span="10">
          <el-input v-model="form.owner" placeholder=""></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"> </el-switch>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit"
          :loading="loading"
          :disabled="loading"
          >确定</el-button
        >
        <el-button @click="cancelClick" :disabled="loading">取消</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>
<script>
import UploadAvatar from "../../upload/upload_avatar.vue";
import MultipleCategory from "../multiple_category.vue";
export default {
  components: {
    UploadAvatar,
    MultipleCategory,
  },
  data() {
    return {
      drawer: false,
      loading: false,
      title: "",
      form: {},
      rules: {
        temp_name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        categoryIds: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
      //选择的类目列表
      categoryList: [],
    };
  },
  emits: ["change", "close"],
  props: ["item", "reload"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.form = Object.assign({}, this.item);
          if (this.item.id) {
            this.title = "修改品牌";
            this.form.temp_name = this.form.name | this.form.en_name;
            this.categoryList = this.form.categories.map((item) => {
              return {
                value: item["id"],
                label: item["name"],
                leaf: item["is_leaf"],
                full_name: item["full_name"],
              };
            });
          } else {
            this.title = "新增品牌";
            this.form.register_type = "0";
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;

          this.$http
            .post("/admin/v1/brand/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.drawer = false;
                this.$emit("change");
                // if (this.reload) {
                //   this.reload();
                // }
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      this.$emit("close");

      //   if (this.reload) {
      //     this.reload();
      //   }
    },

    /**
     * 上传
     */
    uploadChange(url) {
      this.form.logo_url = url;
    },
    /**
     * 选择的类目
     */
    checkedCategory(values) {
      console.log(values);
      values.forEach((item) => {
        if (this.categoryList.findIndex((o) => o.value == item.value) == -1) {
          this.categoryList.push(item);
        }
      });
      this.form.categoryIds = this.categoryList.map((x) => x.value);
    },
    /**
     * 移除
     * @param {*} i
     */
    removeCategory(i) {
      this.categoryList.splice(i, 1);
      this.form.categoryIds = this.categoryList.map((x) => x.value);
    },
  },
};
</script>






